import React from "react"

const EyeCatch = ({ title }) => {
  const hostUrl = "https://placehold.jp/"
  const size = {
    horizontal: 740,
    vertical: 457
  }
  const css = '{"background-color":"aliceblue","color":" #555555","font-size":" 35px","word-break":" break-all","padding":" 25px"}'
  const imageUrl =
      hostUrl + size.horizontal + "x" + size.vertical + ".png?css=" + encodeURIComponent(css) + "&text=" + title

  return (
    <img
      src={imageUrl}
      alt={title}
      className="eyecatch-img"
    />
  )
}

export default EyeCatch